import React from 'react'
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const ButtonField = ({type, name, value, fieldClass, grpmd, customClass}) => (
	<div className={`${customClass}`}>
		<Button variant="" type={type} className={fieldClass}>
			<span>{value}</span>
		</Button>
	</div>
);

export default ButtonField;