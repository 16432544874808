import React from "react";
import { useLocation } from "@reach/router";
//import FloatingTelegram from "../../../images/telegramdesktop.svg"
import FloatingTelegram from "../../../images/telegramdesk.svg";
import TelegramMobile from "../../../images/telegramMobile.png"
import "../TelegramCTA/TelegramCTA.scss";
const TelegramFloating = (props) =>{
    const location = useLocation();
    //console.log("props",props)
   let dubaiTelegram = "+971501536873"
   let qatarTelegram = "+97450867771"
   let contact = ''
    if(location?.pathname?.includes("dubai") ) {
        contact = dubaiTelegram
    }else{
        contact = qatarTelegram 
    }
   
    return(
        <>
       
     <div className=" d-none d-lg-block floating-telegram-box">
              <a  
                
                 href= {`https://telegram.me/${contact}`}
                  target="_blank"
                  className="floating-telegram"
                >
                  <img
                    loading="lazy"
                    src={FloatingTelegram}
                    alt="telegram"
                    title="telegram"
                  />
        </a>
     </div> 
     <div className="d-block d-lg-none telegram-mobile-pos">
             <a
              
              href= {`https://telegram.me/${contact}`}
              target="_blank"
            >
              <img src={TelegramMobile} alt="whatsapp" />
            </a>
     </div> 
     </>
    );
    
}
export default TelegramFloating;
