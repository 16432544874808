import React from "react"
import FloatingWhatsapp from "../../images/floating-whatsapp.svg"
import whatsappLogo from "../../images/whatsapp-icon.svg"

const WhatsApp = ({
  pageName,
  search_results_watsapplink,
  Search_Result,
  Action,
  whatsappNo,
  locationPath,
  mobileWhatsApp,
  floatingWhatsApp,
}) => {
  const baseURL = `https://api.whatsapp.com`
  const phoneNo = `+97450867771`

  var Property_Details_preText,
    Search_Result_preText = ""

  var preText = `Hi%20Pearl%20Gates%2C+I'm%20interested%20in%20${pageName}%20${locationPath?.href}&phone=+${whatsappNo}`
  var searchResult_preText = `Hi%20Pearl%20Gates%2C+I'm%20interested%20in%20this%20search%20results%20${locationPath?.href}&phone=${phoneNo}`

  const propType = type => {
    if (type) {
      return `Hi%20Pearl%20Gates%2C+I'm%20interested%20in%20this%20${type}%20property%20${locationPath?.href}&phone=${phoneNo}`
    }
  }

  if (Search_Result === "Property_Details") {
    Property_Details_preText =
      search_results_watsapplink === "sales"
        ? propType("sale")
        : search_results_watsapplink === "lettings"
        ? propType("rent")
        : ""
    preText = Property_Details_preText
  }

  if (Search_Result === "Commercial") {
    Search_Result_preText =
      Action === "for-sale" || Action === "for-rent" ? searchResult_preText : ""
    preText = Search_Result_preText
  }

  if (Search_Result === "Residential") {
    Search_Result_preText =
      Action === "for-sale" ||
      Action === "for-rent" ||
      Action === "sold" ||
      Action === "let"
        ? searchResult_preText
        : ""
    preText = Search_Result_preText
  }

  return (
    <>
      {mobileWhatsApp && (
        <>
          <a
            href={`${baseURL}/send/?text=${preText}`}
            target="_blank"
            className="btn btn-primary"
          >
            <img src={whatsappLogo} alt="whatsapp" />
          </a>
          <a class="btn btn-primary" href={`tel:+${whatsappNo}`}>
            {`+${whatsappNo}`}
          </a>
        </>
      )}
      {floatingWhatsApp && (
        <>
          <a
            href={`${baseURL}/send/?text=${preText}`}
            target="_blank"
            className={"floating-whatsapp"}
          >
            <img
              loading="lazy"
              src={FloatingWhatsapp}
              alt="whatsapp"
              title="Whatsapp"
            />
          </a>
        </>
      )}
    </>
  )
}
export default WhatsApp
