import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import { useLocation } from "@reach/router";

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, Mail_Function } from "../common/utils"

import axios from "axios"
import * as qs from "query-string"

function FooterContactForm(props) {

  const location = useLocation();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: 'contact form',
      form_type: 'contact form',
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "footer_contact_user",
      email_temp_admin: "footer_contact_admin",
      email_server_func: "contact-member",
      event_tracking: 'contact form',
      page_url: "/about/our-people",
      email_subject_user: "Speak to our local experts today",
      email_subject_admin: "Speak to our local experts today"
    },
    {
      grpmd: "12",
      label: null,
      placeholder: "Enquiry Type",
      name: "enquirytype",
      element: "select",
      required: true,
      values: ['I am an Investor', 'I am a Partner', 'I am a Developer', 'I am a Tenant', 'Interior Decoration', 'Construction Services', 'Mortgage Enquiry', 'Other Enquiry'],
      labelClass: "annual-txt",
      class: "full_wdth_box"
    },
    {
      grpmd: "12",
      label: null,
      placeholder: "Name",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: null,
      placeholder: "Email Id",  
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18"

    },
    {
      grpmd: "12",
      label: null,
      //placeholder: "+971 12345678",
      placeholder: "Phone Number",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18"

    },
    {
      grpmd: "6",
      name: "SUBMIT",
      type: "submit",
      element: "button",
      value: "Send",
      class: "btn-primary ", 
      labelClass: "",
      customClass: "btn-wrapper contact-submit col-md-12"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    if (event.type === "select") {
      event.value = event.value.trimStart()
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset();
          await Mail_Function(formvalues);

          // lets send mail
          // const axiosOptions_email = {
          //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email)
          // .then(response => {
          //   console.log('mail sent!')
          // })
          // .catch(err =>
          //   console.log(err)
          // );

        });

        formvalues['g-recaptcha-response'] = token;

        // const axiosOptions_netlify = {
        //   url: fields[0].page_url,
        //   method: "post",
        //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //   data: qs.stringify(formvalues),
        // }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );
        // const url = typeof window !== 'undefined' ? window.location.href : ''  
        // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        //   fields[0].formname = 'Get mortgage help';
        // }

        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          //'formType': 'form-'+fields[0].event_tracking,
          'formType': formvalues.interested_in,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': props.formLabel ? props.formLabel : ''
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;

      json['extra'] = { "Interested in": json.interested_in };

      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };

  return (
    <div className="form stbform">
      <div ref={myRef} />

      {showerror && <div className="alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert-success">
        <p>{fields[0].success_text}</p>
      </div>}



      <Form name={fields[0].formname} className="footer-contact-form contact-form" action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />

        <input type="hidden" name="bot-field" />
        <Form.Row>
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
              );
            }
          })
          }
        </Form.Row>
      </Form>
    </div>
  );
}


const FooterContactFormPage = (props) => (
  <FooterContactForm to_email_id={props.to_email_id} formName={props.formName} formLabel={props.formLabel} />
)

export default FooterContactFormPage
