import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import { bounceOutUp, slideInDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
//import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import BrandLogo from "../../images/pb-logo-sm.svg"
import BrandXl from "../../images/pb-logo-xl.svg"
import scrollLogo from "../../images/logo-golden.svg" 
import InteriorLogo from "../../images/interior-logo.svg" 

import './Header.scss';
import $ from "jquery";

import { useStaticQuery, graphql } from "gatsby"
//Common for 
import TimeAgo from 'javascript-time-ago'
// English.
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)


// Images


const styles = {
  bounceOutUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceOutUp, 'bounceOutUp')
  },
  slideInDown: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInDown, 'slideInDown')
  },
}

const Header = (props) => {

  const data = useStaticQuery(graphql`
    query HeaderMenuQuery {  
      glstrapi {
        allMenus(sort:"Sort:asc",where:{Publish:true}) {
          id
          Link_Type
          Label
          Primary_URL
          Secondary_URL
          Footer_Links
          Topmenu
          Template
          Publish
          Sort
          Show_On_Menu
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
          secondary_submenu {
            id
            Primary_URL
            Label
          }
        }
      }
    }
  `)

  const [addClass, setAddClass] = useState(false);
  const [topmenuID, setTopmenuID] = useState(0);
  const [submenu, setSubmenu] = useState(false);

  const [scroll, setScroll] = useState(false);

  const [state, setState] = useState({
    logo: false,
    nave: false,
    addClass: false,
  })

  const newClass = () => {
    setAddClass(!addClass)
    setSubmenu(false)
    let domElement = document.querySelector('.overlay')
    if (domElement == null) {
      document.querySelector('body').classList.add('overlay')
    }
    else {
      document.querySelector('body').classList.remove('overlay')
    }
  }

  const openSub = () => {
    setSubmenu(!submenu)
  }

  const openSubmenu = (topmenuID, getSubMenu, menuLabel, e) => {
    e.preventDefault();
    // if(getSubMenu > 0 && menuLabel === "Services"){
    //   setAddClass(!addClass);
    //   setTopmenuID(topmenuID);

    //   let domElement=document.querySelector('.overlay')
    //   if(domElement == null){
    //     document.querySelector('body').classList.add('overlay')
    //   }
    //   else{
    //     document.querySelector('body').classList.remove('overlay')
    //   }
    // }

  }

  const showSubmenu = (e) => {
    e.preventDefault();
    //console.log("event", e.target.parentElement);
    $(e.target).toggleClass('actv');
    $('.mega-menu', e.target.parentElement).toggle('fade');
  }

  const showSecSubmenu = (e) => {
    e.preventDefault();
    //console.log("event", e.target.parentElement);
    $(e.target).toggleClass('actv');
    $('.sub-menu', e.target.parentElement.parentElement).toggle('fade');
  }
  const triggerClick = (e) => {
    e.preventDefault();
    $(e.target).next('.plus-icon').trigger('click');
  }
  const showThirdSubmenu = (e) => {
    e.preventDefault();
    //console.log("event", e.target.parentElement);
    $(e.target).toggleClass('actv');
    $('.secondary-menu', e.target.parentElement.parentElement).toggle('fade');
  }



  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 130)
    })
    // useEffect(() => {
    //   $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">');
    // });
  }, []);

  var menu_data =  data.glstrapi.allMenus.filter(function (option) {
    return option.Show_On_Menu === true;
  });

  // console.log("mk menu_data", menu_data);
  const location = useLocation();
  var contact_btn_link = location?.pathname.indexOf('dubai') >= 0 ? "/our-offices/real-estate-agents-in-dubai/" : "/our-offices/real-estate-agents-in-qatar/"
  if(location?.pathname == '/mortgage-services' || location?.pathname == '/mortgage-services/'){
    contact_btn_link = "/mortgage-services/mortgage-contact/"
  }
  var header_cls = props.Layout === "Without_Banner" ? "header-two" : '';
  var contact_btn_cls = props.Layout === "Without_Banner" ? "white_bdr" : '';

 

  var interorPage = false;
  if(location.pathname.includes('interior-design-in-qatar')){
    interorPage = true;
  }

  return (
    <>   
      <header className={scroll ? `header ${header_cls} sroll` : `header ${header_cls}`}>
        <Container fluid className="d-flex justify-content-between ">
          <Nav>
            <Navbar className="p-0" expand="lg">
              <Link to="/">
                <StyleRoot>
                  {
                    header_cls === "header-two" ? (
                      <picture onMouseEnter={() => setState({ ...state, logo: true })} onMouseLeave={() => setState({ ...state, logo: false })} style={state.logo ? styles.none : null}>
                        <img className="logo" src={interorPage ? InteriorLogo: scrollLogo} alt="Logo" />
                      </picture>
                    ) : (
                      <>
                        <picture className="normal-logo">
                          <source media="(min-width:992px)" srcset={BrandXl} />
                          <img className="logo" src={BrandLogo} alt="Logo" />
                        </picture>
                        <picture className="scroll-logo">
                          <img className="logo"src={interorPage ? InteriorLogo: scrollLogo} alt="Logo" />
                        </picture>
                      </>
                    )
                  }

                </StyleRoot>
              </Link>
              <Navbar.Collapse className={state.nave ? 'navigation-wrap show active-nav' : 'navigation-wrap show'}>
                <Link to={contact_btn_link} className={`btn btn-primary mb_contact_btn`}><span>Contact Us</span></Link>
                <span className="close-btn d-lg-none" onClick={() => setState({ nave: false })}><i className="close-icon"></i></span>
                <Nav>
                  <div className="navigation">
                    <ul className="navigation-list">
                      {
                        menu_data && Object.keys(menu_data).filter(key => menu_data[key].Topmenu === true).map((key, index) => {

                          var getSubMenu = menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Main_Parent.id === menu_data[key].id && menu_data[key].Primary_URL !== "mortgage-services")).length;

                          // console.log("getSubMenu", getSubMenu);

                          return (
                            <li className={`parent_menu ${getSubMenu > 0 ? 'has-submenu' : ''}`} id={''}>


                              <Link to={`/${menu_data[key].Secondary_URL ? menu_data[key].Secondary_URL : menu_data[key].Primary_URL}`} className={addClass && topmenuID === menu_data[key].id ? "active-mega" : ""}>{menu_data[key].Label} </Link>
                              {getSubMenu > 0 ? <i className="plus-icon" onClick={showSubmenu}></i> : ''}

                              <div className="mega-menu">
                                <div className="mega-menu-wrap">
                                  {
                                    menu_data[key].Label === "Services" ? (
                                      <Row>
                                        {menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Main_Parent.id === menu_data[key].id) && menu_data[key2].Sub_Parent === null).map((key2, index) => {
                                          return (
                                            <Col lg="4">
                                              <div className={'h3'}>
                                                <Link to={`/${menu_data[key2].Main_Parent.Primary_URL}/${menu_data[key2].Primary_URL}`}>{menu_data[key2].Label}</Link> <i className="plus-icon" onClick={showSecSubmenu}></i>
                                              </div>
                                              <ul className="sub-menu">
                                                {menu_data && Object.keys(menu_data).filter(key3 => menu_data[key3].Main_Parent !== null && menu_data[key3].Sub_Parent !== null && menu_data[key3].secondary_submenu === null && menu_data[key3].Sub_Parent.id === menu_data[key2].id).sort((a, b) => (a.Sort > b.Sort) ? 1 : ((b.Sort > a.Sort) ? -1 : 0)).map((key3, index) => {
                                                  return (
                                                    <li className="primary_submenu">
                                                      {
                                                        menu_data[key3].Secondary_URL ? (
                                                          menu_data[key3].Secondary_URL === "javascript:;" ?
                                                            <a href="javascript:;" onClick={triggerClick}>{menu_data[key3].Label}</a> : <a href={`${menu_data[key3].Secondary_URL}`}>{menu_data[key3].Label}</a>
                                                        ) : (
                                                          <Link to={`/${menu_data[key3].Main_Parent.Primary_URL}/${menu_data[key3].Sub_Parent.Primary_URL}/${menu_data[key3].Primary_URL}`}>{menu_data[key3].Label}</Link>
                                                        )
                                                      }


                                                      {menu_data && Object.keys(menu_data).filter(key4 => menu_data[key4].Main_Parent !== null && menu_data[key4].Sub_Parent !== null && menu_data[key4].secondary_submenu !== null && menu_data[key4].secondary_submenu.id === menu_data[key3].id).length > 0 && (
                                                        <>
                                                          <i className="plus-icon" onClick={showThirdSubmenu}></i>

                                                          <ul className="secondary-menu">
                                                            {menu_data && Object.keys(menu_data).filter(key4 => menu_data[key4].Main_Parent !== null && menu_data[key4].Sub_Parent !== null && menu_data[key4].secondary_submenu !== null && menu_data[key4].secondary_submenu.id === menu_data[key3].id).map((key4, index) => {
                                                              return (
                                                                <li>
                                                                  {/* <Link to={`/${menu_data[key4].Main_Parent.Primary_URL}/${menu_data[key4].Sub_Parent.Primary_URL}/${menu_data[key4].secondary_submenu.Primary_URL}/${menu_data[key4].Primary_URL}`}>{menu_data[key4].Label}</Link>  */}

                                                                  {menu_data[key4].Secondary_URL ?
                                                                    <Link to={`/${menu_data[key4].Secondary_URL}`}>{menu_data[key4].Label} </Link>
                                                                    :
                                                                    <Link to={`/${menu_data[key4].Main_Parent.Primary_URL}/${menu_data[key4].Sub_Parent.Primary_URL}/${menu_data[key4].secondary_submenu.Primary_URL}/${menu_data[key4].Primary_URL}`}>{menu_data[key4].Label}</Link>
                                                                  }


                                                                </li>
                                                              )
                                                            })
                                                            }
                                                          </ul>
                                                        </>

                                                      )}

                                                    </li>
                                                  )
                                                })
                                                }
                                              </ul>
                                            </Col>
                                          )
                                        })
                                        }
                                      </Row>
                                    ) : (<ul>
                                      {menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Main_Parent.id === menu_data[key].id) && menu_data[key2].Sub_Parent === null).map((key2, index) => {
                                        return (<li><div className={'h3'}><Link to={`/${menu_data[key2].Main_Parent.Primary_URL}/${menu_data[key2].Primary_URL}`}>{menu_data[key2].Label}</Link></div></li>)
                                      })
                                      }
                                    </ul>)
                                  }

                                </div>
                              </div>

                            </li>
                          )
                        })
                      }

                    </ul>
                  </div>

                </Nav>

              </Navbar.Collapse>
              <div className="nav-right ml-auto">
                <Link to={contact_btn_link} className={`btn btn-primary d-none d-md-inline-block ${contact_btn_cls}`}><span>Contact Us</span></Link>
                <Button className="mune-icon d-lg-none" onClick={() => setState({ nave: true })} variant="link"></Button>
              </div>
            </Navbar>
          </Nav>
        </Container>


      </header>
    </>
  )
}



export default Header
