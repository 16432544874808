import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import loadable from "@loadable/component";
const DatePicker = loadable(() => import("./datepicker"));



const InputField = ({startDate, setStartDate,fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, step, inputlabel, max, maxlength }) => {
  
return (
  <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
    <div className="custom-float">   
    {(type == 'datetime' || type == 'date_time' || type == 'valuation_date_time') ?
    <DatePicker
    {...{startDate, setStartDate,fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, step, inputlabel, max, maxlength }}
    />
    :
    <>
    { label ? <Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label> : '' }
    <Form.Control
      className={fieldClass}
      required={required}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handlechange}
      pattern={pattern}
      autocomplete="off"
      max={max}
      maxLength={maxlength}
    /> 
    </>
  }
    </div>
  </Form.Group>
)
}

export default InputField;