import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Select, { components } from "react-select";
import "../../Home/SelectBox/SelectBox.scss";

const SelectField = ({name, required, placeholder, labelClass, values, handlechange, fieldClass, label, grpmd, componentprops, isError, selectedValue}) => { 
  const selectOption = [];
  values.map((item,index) => {
    selectOption.push({ value: item, label: item, type:"select"})
  })

  //console.log("selectedValue", selectedValue, selectOption)
  let defaultVal =  selectOption[0]
  if(placeholder){
    defaultVal = placeholder
  }
  
  return(
  <Form.Group as={Col} md={grpmd} controlId={`validation${name}`}>
    { label
      ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>
      : ''

    }
    {/* <Form.Control
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
		{values.map(val =><option value={val} key="">{val}</option>)}
    </Form.Control> */}
    <div className={"select-group " + (isError ? "has-error" : "")}>
      <Select
        className={fieldClass}
        isSearchable={false}
        placeholder={placeholder}
        name={name}
        value={componentprops ? componentprops[name] : ''}
        defaultValue={selectedValue ? selectOption.filter(function(option) {
          return option.value === selectedValue;
        }) : defaultVal}
        onChange={handlechange}
        options={selectOption}
        required={required}
        classNamePrefix={"select-opt"}
      />
    </div>
  </Form.Group>
  )
}

export default SelectField;