import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby" 
import axios from "axios"
import * as qs from "query-string"
import { Col } from 'react-bootstrap';

import bgLogoImg from "../../images/result/bg-logo-img.png"

export const mail_form_url = `${process.env.GATSBY_CLOUD_URL}/api/form`;
//export const mail_form_url = `/api/form`;

export const Mail_Function = (formvalues) => {
  window.fetch(`${mail_form_url}`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(formvalues),
  })
}

export const youtube_parser = (url) => {
  var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  var match = url.match(regExp);
  return (match && match[1].length == 11) ? match[1] : false;
}

export const uniqueID = () => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  for (var i = 0; i < 20; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export const addCommas = num => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";

export const removeNonNumeric = num => num ? num.toString().replace(/[^0-9]+/g, "") : "";

export const numericWithDecimal = num => num ? num.toString().replace(/[^0-9\.]+/g, "") : "";

export const stb_sale_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/property-for-sale";
export const stb_rent_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/property-to-rent";
export const stb_commercial_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/commercial-property";
export const stb_sold_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/sold-properties?pid=60f925fa30f8f877f44a9c58";
export const stb_let_link = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/let-properties";

export const numberOnly = (value) => {
  if (value) {
    if (!/^[0-9]+$/.test(value)) {
      //alert("Please only enter numeric characters.")
      return "Please enter  only numeric characters.";
    }
  }
}

export const externalURL = (link) => {
  if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
    return true;
  }
  else {
    return false;
  }
}

export const phoneNumberValidation = (num) => {
  if (num) {
    return num.toString().replace(/[^0-9\+ ]+/g, "")
  } else {
    return false;
  }
}

export const randomArrayShuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export const MarketAds = () => {
  return (
    <Col md={6} lg={4} className={'amenities_block'}>
       <div className="animated">
        <div className="info-card d-flex align-items-center">
          <img className="cardbg-logo" src={bgLogoImg} alt="img" />
          <div className="wrap">
            <h2 className="info-title">Are you ready to apply for a real estate ownership?</h2>
            {/* <p className="info-desc d-none d-xl-block">Do you want to see properties with.
            </p>
            <p className="info-desc d-xl-none">Do you want to see properties with.
            </p> */}
            <a href={process.env.GATSBY_MARKET_AD_LINK} target="_blank" className="btn btn-primary amenities-block"><span>Know More</span></a>
          </div>
        </div>
       </div>
    </Col>
  )
}

// Property amenities slider
export const amenitiesSliderArray = [
  { label: "security", value: "security" },
  { label: "concierge-service", value: "concierge-service", },
  { label: "covered-parking", value: "covered-parking" },
  { label: "built-wardrobes", value: "built-wardrobes" },
  { label: "central-a-c-heating", value: "central-a-c-heating" },
  { label: "built-kitchen-appliances", value: "built-kitchen-appliances" },
  { label: "shared-gym", value: "shared-gym" },
  { label: "shared-pool", value: "shared-pool" },
  { label: "balcony", value: "balcony" },
  { label: "view-of-water", value: "view-of-water" },
  { label: "view-of-landmark", value: "view-of-landmark" },
  { label: "pets-allowed", value: "pets-allowed" },
  { label: "walk-closet", value: "walk-closet" },
  { label: "shared-spa", value: "shared-spa" },
  { label: "maids-room", value: "maids-room" },
  { label: "sea-view", value: "sea-view" },
  { label: "study", value: "study" },
  { label: "maid-service", value: "maid-service" },
  { label: "private-pool", value: "private-pool" },
  { label: "private-garden", value: "private-garden" },
  { label: "private-gym", value: "private-gym" },
  { label: "waterfront", value: "waterfront" },
  { label: "available-furnished", value: "available-furnished" },
  { label: "beachfront-property", value: "beachfront-property" },
  { label: "conference-room", value: "conference-room" },
  { label: "retail-building", value: "retail-building" },
  { label: "availabe-networked", value: "availabe-networked" },
  { label: "backyards", value: "backyards" },
  { label: "rooftop-terrace", value: "rooftop-terrace" },
  { label: "terrace-garden", value: "terrace-garden" }
]

// Property amenities checkboxes
export const amenitiesCheckboxArray = [
  { label: "security", value: "security" },
  { label: "concierge-service", value: "concierge-service", },
  { label: "covered-parking", value: "covered-parking" },
  { label: "built-wardrobes", value: "built-wardrobes" },
  { label: "central-a-c-heating", value: "central-a-c-heating" },
  { label: "built-kitchen-appliances", value: "built-kitchen-appliances" },
  { label: "shared-gym", value: "shared-gym" },
  { label: "shared-pool", value: "shared-pool" },
  { label: "balcony", value: "balcony" },
  { label: "view-of-water", value: "view-of-water" },
  { label: "view-of-landmark", value: "view-of-landmark" },
  { label: "pets-allowed", value: "pets-allowed" },
  { label: "walk-closet", value: "walk-closet" },
  { label: "shared-spa", value: "shared-spa" },
  { label: "maids-room", value: "maids-room" },
  { label: "sea-view", value: "sea-view" },
  { label: "study", value: "study" },
  { label: "maid-service", value: "maid-service" },
  { label: "private-pool", value: "private-pool" },
  { label: "private-garden", value: "private-garden" },
  { label: "private-gym", value: "private-gym" },
  { label: "waterfront", value: "waterfront" },
  { label: "available-furnished", value: "available-furnished" },
  { label: "beachfront-property", value: "beachfront-property" },
  { label: "conference-room", value: "conference-room" },
  { label: "retail-building", value: "retail-building" },
  { label: "availabe-networked", value: "availabe-networked" },
  { label: "backyards", value: "backyards" },
  { label: "rooftop-terrace", value: "rooftop-terrace" },
  { label: "terrace-garden", value: "terrace-garden" }
]

// Property amenities checkbox - commercial
export const amenitiesCheckboxArrayComm = [
  { label: "covered-parking", value: "covered-parking" },
  { label: "available-furnished", value: "available-furnished" },
  { label: "conference-room", value: "conference-room" },
  { label: "retail-building", value: "retail-building" },
  { label: "availabe-networked", value: "availabe-networked" },
  { label: "view-of-landmark", value: "view-of-landmark" },
]

// Property amenities slider - commercial
export const amenitiesSliderArrayComm = [
  { label: "covered-parking", value: "covered-parking" },
  { label: "available-furnished", value: "available-furnished" },
  { label: "conference-room", value: "conference-room" },
  { label: "retail-building", value: "retail-building" },
  { label: "availabe-networked", value: "availabe-networked" },
  { label: "view-of-landmark", value: "view-of-landmark" },
]

// Property amenities checkbox - commercial
export const SearchBoxOptions = [
  { value: "The Pearl", label: "The Pearl" },
  { value: "Lusail City", label: "Lusail City" },
  { value: "West Bay", label: "West Bay" },
  { value: "West bay Lagoon", label: "West bay Lagoon" },
  { value: "Msheireb Downtown Doha", label: "Msheireb Downtown Doha" },
];

// Price Min Sale
export const priceminRangeSale = [
  { label: "Min Price", value: "0" },
  { label: "500,000 QR", value: "500000" },
  { label: "2,000,000 QR", value: "2000000" },
  { label: "4,000,000 QR", value: "4000000" },
  { label: "6,000,000 QR", value: "6000000" },
  { label: "8,000,000 QR", value: "8000000" },
  { label: "10,000,000 QR", value: "10000000" },
  { label: "12,000,000 QR", value: "12000000" },
  { label: "14,000,000 QR", value: "14000000" },
  { label: "15,000,000 QR", value: "15000000" }
]

// Price Max Sale
export const pricemaxRangeSale = [
  { label: "Max Price", value: "0" },
  { label: "1,000,000 QR", value: "1000000" },
  { label: "2,000,000 QR", value: "2000000" },
  { label: "4,000,000 QR", value: "4000000" },
  { label: "6,000,000 QR", value: "6000000" },
  { label: "8,000,000 QR", value: "8000000" },
  { label: "10,000,000 QR", value: "10000000" },
  { label: "12,000,000 QR", value: "12000000" },
  { label: "14,000,000 QR", value: "14000000" },
  { label: "15,000,000 QR", value: "15000000" }
]

// Price Min Rent
export const priceminRangeRent = [
  { label: "Min Price", value: "0" },
  { label: "5,000 QR", value: "5000" },
  { label: "10,000 QR", value: "10000" },
  { label: "15,000 QR", value: "15000" },
  { label: "25,000 QR", value: "25000" },
  { label: "35,000 QR", value: "35000" },
  { label: "55,000 QR", value: "55000" },
  { label: "65,000 QR", value: "65000" },
  { label: "75,000 QR", value: "75000" }
]

// Price Max Sale
export const pricemaxRangeRent = [
  { label: "Max Price", value: "0" },
  { label: "10,000 QR", value: "10000" },
  { label: "15,000 QR", value: "15000" },
  { label: "25,000 QR", value: "25000" },
  { label: "35,000 QR", value: "35000" },
  { label: "55,000 QR", value: "55000" },
  { label: "65,000 QR", value: "65000" },
  { label: "75,000 QR", value: "75000" },
]

export const SaleType = [
  { value: "", label: "All" },
  { value: "resale", label: "Resale" },
  { value: "primary-market", label: "Primary Market" },
  { value: "off-plan", label: "Off Plan" },
  { value: "lease-to-own", label: "Lease to Own" },
];

export const CalculatorFields = {
  PriceSliderMax: 99999999,
  PriceInputMax: 10,
  InterestSliderMax: 15,
  InterestInputMax: 5,
  YearSliderMax: 35,
  YearInputMax: 2,
  DownPaymentSliderMax: 15,
  DownPaymentInputMax: 2,
  FeesSliderMax: 99999,
  FeesInputMax: 6,
  DownPaymentPercMax: 99,
}
 